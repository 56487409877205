
import Vue from 'vue'

export default Vue.extend({
  name: 'Videopill',
  prop: ['value'],
  props: {
    name: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    colore: {
      type: String,
      default: '#fff'
    },
    image: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    track () {
      this.$gtm.push({
        event: 'click_video_pills',
        event_data: {
          video_name: this.name !== '' ? this.name : this.link,
          video_url: this.link,
          page_url: this.link,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
