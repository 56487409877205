
import Vue from 'vue'

export default Vue.extend({
  name: 'Event',
  prop: ['value'],
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    track () {
      this.$gtm.push({
        event: 'click_event',
        event_data: {
          event_name: this.title,
          event_url: this.link,
          is_special: false,
          page_url: this.$router.currentRoute.fullPath,
          page_title: this.title
        }
      })
    }
  }
})
