
import Vue from 'vue'

export default Vue.extend({
  name: 'University',
  prop: ['value'],
  props: {
    name: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    track () {
      this.$gtm.push({
        event: 'click_partner',
        event_data: {
          partner_name: this.name,
          partner_url: this.link,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
