
import Vue from 'vue'

export default Vue.extend({
  name: 'Job',
  prop: ['value'],
  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    cityslug: {
      type: String,
      default: 'no-city'
    },
    description: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    cid: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: ''
    },
    unlock: {
      type: Number || String,
      default: 0
    },
    target: {
      type: String,
      default: ''
    },
    verified: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      $gtm: null
    }
  },
  computed: {
    url () {
      const query = { id: this.cid, verified: 'true' }
      if (this.tag !== '') {
        query.tag = this.tag
      }
      if (this.unlock !== 0) {
        query.suggested = 'true'
        query.ea = '0'
      }
      return this.localePath({ name: 'radar-city-slug', params: { city: this.cityslug !== null ? this.cityslug : 'no-city', slug: this.slug }, query })
    }
  },
  methods: {
    stripHtml (html) {
      if (process.browser) {
        const tmp = document.createElement('div')
        tmp.innerHTML = html
        return tmp.textContent || tmp.innerText || ''
      } else {
        return html
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_job_application',
        event_data: {
          company_name: this.name,
          job_application_name: this.title,
          job_application_place: this.city,
          is_stage: this.contract,
          verified: true,
          orp_value: this.unlock !== 0 ? this.unlock : '',
          orp: this.unlock !== 0,
          page_url: this.url,
          page_title: document ? document?.title : 'CVing'
        }
      })
      this.$router.push(this.url)
    }
  }
})
