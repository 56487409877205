
import Vue from 'vue'

export default Vue.extend({
  name: 'Article',
  prop: ['value'],
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    track () {
      this.$gtm.push({
        event: 'click_news',
        event_data: {
          news_title: this.title,
          news_url: this.link,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
