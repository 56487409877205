var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home",
    on: {
      "scroll": _vm.showPopup
    }
  }, [_c('h1', {
    staticClass: "visually-hidden"
  }, [_vm._v("\n    CVing\n  ")]), _vm._v(" "), (_vm.$i18n.locale === 'it' || _vm.mainAlwaysVisible) && _vm.getSlider && _vm.getSlider.length > 0 ? _c('Carousel', {
    attrs: {
      "slides": _vm.getSlider
    }
  }) : _vm._e(), _vm._v(" "), _vm.$i18n.locale === 'it' || _vm.mainAlwaysVisible ? _c('main', {
    staticClass: "home__main"
  }, [_c('section', {
    staticClass: "section section--search"
  }, [_c('header', [_c('h2', [_vm._v("\n          " + _vm._s(_vm.$t('home.search')) + "\n        ")])]), _vm._v(" "), _c('form', {
    staticClass: "home__search"
  }, [_c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('home.what')))]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.text,
      expression: "search.text"
    }],
    key: _vm.placeholder,
    ref: "homewhat",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.search.text
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchCampaigns();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "text", $event.target.value);
      }
    }
  })]), _vm._v(" "), _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('home.where')))]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.city,
      expression: "search.city"
    }],
    attrs: {
      "placeholder": _vm.$t('home.city')
    },
    domProps: {
      "value": _vm.search.city
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchCampaigns();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "city", $event.target.value);
      }
    }
  })]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "home__search-button",
    attrs: {
      "to": _vm.localePath(_vm.link),
      "aria-label": _vm.$t('home.search')
    }
  }, [_c('span', {
    attrs: {
      "hidden": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('home.search'))
    }
  }), _vm._v(" "), _c('fa', {
    attrs: {
      "icon": ['fas', 'search']
    }
  })], 1), _vm._v(" "), _c('a', {
    staticClass: "button button--orp",
    attrs: {
      "href": "https://business.cving.com/openreferralprogram",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": '/assets/images/euro-bag.png',
      "width": "20",
      "height": "20",
      "alt": ""
    }
  }), _vm._v("\n          " + _vm._s(_vm.$t('home.suggestAndMonetize')) + "\n        ")])], 1)]), _vm._v(" "), _vm.companies.length ? _c('section', {
    staticClass: "section companies",
    attrs: {
      "id": "contenitore-aziende"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.featuredCompanies')))]), _vm._v(" "), _vm.companies.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'all-wp',
        params: {
          wp: 'companies'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--companies"
  }, _vm._l(_vm.companies, function (item, index) {
    var _item$acf, _item$acf$logo, _item$acf$logo$sizes, _item$acf2, _item$acf2$immagine, _item$acf2$immagine$s, _item$acf3, _item$acf4, _item$acf5, _item$acf5$citta, _item$acf6, _item$acf6$citta, _item$acf7;
    return _c('Company', {
      key: index,
      attrs: {
        "id": 'singola-azienda-' + (index + 1),
        "logo": item.acf.logo ? (_item$acf = item.acf) === null || _item$acf === void 0 ? void 0 : (_item$acf$logo = _item$acf.logo) === null || _item$acf$logo === void 0 ? void 0 : (_item$acf$logo$sizes = _item$acf$logo.sizes) === null || _item$acf$logo$sizes === void 0 ? void 0 : _item$acf$logo$sizes.medium : '',
        "cover": item.acf.immagine ? (_item$acf2 = item.acf) === null || _item$acf2 === void 0 ? void 0 : (_item$acf2$immagine = _item$acf2.immagine) === null || _item$acf2$immagine === void 0 ? void 0 : (_item$acf2$immagine$s = _item$acf2$immagine.sizes) === null || _item$acf2$immagine$s === void 0 ? void 0 : _item$acf2$immagine$s.large : '',
        "description": (_item$acf3 = item.acf) === null || _item$acf3 === void 0 ? void 0 : _item$acf3.descrizione,
        "link": (_item$acf4 = item.acf) === null || _item$acf4 === void 0 ? void 0 : _item$acf4.link,
        "city": ((_item$acf5 = item.acf) === null || _item$acf5 === void 0 ? void 0 : (_item$acf5$citta = _item$acf5.citta) === null || _item$acf5$citta === void 0 ? void 0 : _item$acf5$citta.split(/\,/g).length) > 1 ? _vm.$t('radar.citiesCount', {
          count: (_item$acf6 = item.acf) === null || _item$acf6 === void 0 ? void 0 : (_item$acf6$citta = _item$acf6.citta) === null || _item$acf6$citta === void 0 ? void 0 : _item$acf6$citta.split(/\,/g).length
        }) : (_item$acf7 = item.acf) === null || _item$acf7 === void 0 ? void 0 : _item$acf7.citta,
        "name": item.title.rendered,
        "positions": item === null || item === void 0 ? void 0 : item.count
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--companies', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--companies', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _c('section', {
    staticClass: "section sectors",
    attrs: {
      "id": "contenitore-settori"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.chooseSector')))])]), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('div', {
    staticClass: "scroller__list scroller__list--sectors"
  }, _vm._l(_vm.getVerticali, function (item, index) {
    return _c('VideoSector', {
      key: index,
      attrs: {
        "url": item === null || item === void 0 ? void 0 : item.redirect,
        "video-id": item === null || item === void 0 ? void 0 : item.id,
        "video": item,
        "on-air": item === null || item === void 0 ? void 0 : item.onAir
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--sectors', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--sectors', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]), _vm._v(" "), _vm.jobs.length ? _c('section', {
    staticClass: "section jobs",
    attrs: {
      "id": "contenitore-posizioni-lavorative"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.jobPositions')))]), _vm._v(" "), _vm.jobs.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'radar',
        query: {
          p: '1',
          ea: '0',
          verified: 'false',
          suggested: true,
          order: '0'
        }
      })
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('home.seeAll')) + "\n            "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1)]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--jobs"
  }, _vm._l(_vm.jobs, function (item, index) {
    var _item$city, _item$city2, _item$city3;
    return _c('Job', {
      key: index,
      attrs: {
        "id": 'singola-posizione-' + (index + 1),
        "name": item.company.co_name,
        "title": item.title,
        "city": ((_item$city = item.city) === null || _item$city === void 0 ? void 0 : _item$city.split(/\|/g).length) > 1 ? _vm.$t('radar.citiesCount', {
          count: (_item$city2 = item.city) === null || _item$city2 === void 0 ? void 0 : _item$city2.split(/\|/g).length
        }) : (_item$city3 = item.city) === null || _item$city3 === void 0 ? void 0 : _item$city3.replace(/\|/g, ', '),
        "cityslug": item.cityslug === '' ? 'no-city' : item.cityslug,
        "description": item.description,
        "slug": item.slug,
        "logo": item.company.logo_url,
        "cover": item.company.cover_url,
        "cid": item.cid,
        "type": 'job',
        "unlock": Number(item.unlockamount),
        "contract": (item === null || item === void 0 ? void 0 : item.contract_type) === 8
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--jobs', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--jobs', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.suggestable.length ? _c('section', {
    staticClass: "section jobs",
    attrs: {
      "id": "contenitore-posizioni suggeribili"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.topReferral')))]), _vm._v(" "), _vm.suggestable.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'radar',
        query: {
          p: '1',
          ea: '1',
          verified: 'false',
          suggested: true,
          order: '1'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--suggestables"
  }, _vm._l(_vm.suggestable, function (item, index) {
    var _item$city4, _item$city5, _item$city6;
    return _c('Job', {
      key: index,
      attrs: {
        "id": 'singola-proposta-top-referral' + (index + 1),
        "name": item.company.co_name,
        "title": item.title,
        "city": ((_item$city4 = item.city) === null || _item$city4 === void 0 ? void 0 : _item$city4.split(/\|/g).length) > 1 ? _vm.$t('radar.citiesCount', {
          count: (_item$city5 = item.city) === null || _item$city5 === void 0 ? void 0 : _item$city5.split(/\|/g).length
        }) : (_item$city6 = item.city) === null || _item$city6 === void 0 ? void 0 : _item$city6.replace(/\|/g, ', '),
        "cityslug": item.cityslug === '' ? 'no-city' : item.cityslug,
        "description": item.description,
        "slug": item.slug,
        "logo": item.company.logo_url,
        "cover": item.company.cover_url,
        "cid": item.cid,
        "type": 'stage',
        "unlock": Number(item.unlockamount),
        "contract": (item === null || item === void 0 ? void 0 : item.contract_type) === 8
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--suggestables', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--suggestables', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.stages.length ? _c('section', {
    staticClass: "section jobs",
    attrs: {
      "id": "contenitore-proposte-stage"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.stageProposals')))]), _vm._v(" "), _vm.stages.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'radar',
        query: {
          search: 'stage',
          p: '1',
          ea: '1',
          verified: 'false'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--stages"
  }, _vm._l(_vm.stages, function (item, index) {
    return _c('Job', {
      key: index,
      attrs: {
        "id": 'singola-proposta-stage-' + (index + 1),
        "name": item.company.co_name,
        "title": item.title,
        "city": item.city,
        "cityslug": item.cityslug === '' ? 'no-city' : item.cityslug,
        "description": item.description,
        "slug": item.slug,
        "logo": item.company.logo_url,
        "cover": item.company.cover_url,
        "cid": item.cid,
        "type": 'stage',
        "contract": (item === null || item === void 0 ? void 0 : item.contract_type) === 8
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--stages', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--stages', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.news.length ? _c('section', {
    staticClass: "section news",
    attrs: {
      "id": "contenitore-news"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.blog')))]), _vm._v(" "), _c('a', {
    attrs: {
      "href": "https://media.cving.com",
      "target": "_blank"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--news"
  }, _vm._l(_vm.news, function (item, index) {
    var _item$acf8, _item$acf9, _item$acf10, _item$acf11, _item$_embedded$wpFe, _item$_embedded$wpFe$, _item$_embedded$wpFe$2;
    return _c('News', {
      key: index,
      attrs: {
        "id": 'singola-news-' + (index + 1),
        "title": item.title.rendered,
        "link": item.link,
        "description": ((_item$acf8 = item.acf) === null || _item$acf8 === void 0 ? void 0 : _item$acf8.descrizione) !== null && ((_item$acf9 = item.acf) === null || _item$acf9 === void 0 ? void 0 : _item$acf9.descrizione) !== '' && ((_item$acf10 = item.acf) === null || _item$acf10 === void 0 ? void 0 : _item$acf10.descrizione) !== undefined ? (_item$acf11 = item.acf) === null || _item$acf11 === void 0 ? void 0 : _item$acf11.descrizione : item.yoast_head_json.description !== null ? item.yoast_head_json.description : '',
        "image": item._embedded['wp:featuredmedia'] === undefined ? '' : (_item$_embedded$wpFe = item._embedded['wp:featuredmedia']['0'].media_details) === null || _item$_embedded$wpFe === void 0 ? void 0 : (_item$_embedded$wpFe$ = _item$_embedded$wpFe.sizes) === null || _item$_embedded$wpFe$ === void 0 ? void 0 : (_item$_embedded$wpFe$2 = _item$_embedded$wpFe$.medium) === null || _item$_embedded$wpFe$2 === void 0 ? void 0 : _item$_embedded$wpFe$2.source_url,
        "date": item.date
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--news', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--news', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.pills.length ? _c('section', {
    staticClass: "section pills",
    attrs: {
      "id": "contenitore-videopillole"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.videopills')))]), _vm._v(" "), _vm.pills.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'all-wp',
        params: {
          wp: 'pills'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--pills"
  }, _vm._l(_vm.pills, function (item, index) {
    var _item$acf12, _item$acf13, _item$acf14, _item$acf14$immagine, _item$acf14$immagine$;
    return _c('VideoPill', {
      key: index,
      attrs: {
        "id": 'singola-videopillola-' + (index + 1),
        "name": item.title.rendered,
        "link": (_item$acf12 = item.acf) === null || _item$acf12 === void 0 ? void 0 : _item$acf12.link,
        "colore": (_item$acf13 = item.acf) === null || _item$acf13 === void 0 ? void 0 : _item$acf13.colore_testo,
        "image": item.acf.immagine ? (_item$acf14 = item.acf) === null || _item$acf14 === void 0 ? void 0 : (_item$acf14$immagine = _item$acf14.immagine) === null || _item$acf14$immagine === void 0 ? void 0 : (_item$acf14$immagine$ = _item$acf14$immagine.sizes) === null || _item$acf14$immagine$ === void 0 ? void 0 : _item$acf14$immagine$.large : ''
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--pills', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--pills', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.events.length ? _c('section', {
    staticClass: "section events",
    attrs: {
      "id": "contenitore-eventi"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.events')))]), _vm._v(" "), _vm.events.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'all-wp',
        params: {
          wp: 'events'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--events"
  }, _vm._l(_vm.events, function (item, index) {
    var _item$acf15, _item$acf16, _item$acf17, _item$acf17$immagine, _item$acf17$immagine$, _item$acf18;
    return _c('Event', {
      key: index,
      attrs: {
        "id": 'singolo-evento-' + (index + 1),
        "title": item.title.rendered,
        "link": (_item$acf15 = item.acf) === null || _item$acf15 === void 0 ? void 0 : _item$acf15.link,
        "description": (_item$acf16 = item.acf) === null || _item$acf16 === void 0 ? void 0 : _item$acf16.descrizione,
        "image": item.acf.immagine ? (_item$acf17 = item.acf) === null || _item$acf17 === void 0 ? void 0 : (_item$acf17$immagine = _item$acf17.immagine) === null || _item$acf17$immagine === void 0 ? void 0 : (_item$acf17$immagine$ = _item$acf17$immagine.sizes) === null || _item$acf17$immagine$ === void 0 ? void 0 : _item$acf17$immagine$.large : '',
        "date": (_item$acf18 = item.acf) === null || _item$acf18 === void 0 ? void 0 : _item$acf18.quando
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--events', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--events', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.specials.length ? _c('section', {
    staticClass: "section events",
    attrs: {
      "id": "contenitore-eventi-speciali"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.specialEditions')))]), _vm._v(" "), _vm.specials.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'all-wp',
        params: {
          wp: 'specials'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--specials"
  }, _vm._l(_vm.specials, function (item, index) {
    var _item$acf19, _item$acf20, _item$acf21, _item$acf21$immagine, _item$acf21$immagine$, _item$acf22;
    return _c('Special', {
      key: index,
      attrs: {
        "id": 'singolo-evento-speciale-' + (index + 1),
        "title": item.title.rendered,
        "link": (_item$acf19 = item.acf) === null || _item$acf19 === void 0 ? void 0 : _item$acf19.link,
        "description": (_item$acf20 = item.acf) === null || _item$acf20 === void 0 ? void 0 : _item$acf20.descrizione,
        "image": item.acf.immagine ? (_item$acf21 = item.acf) === null || _item$acf21 === void 0 ? void 0 : (_item$acf21$immagine = _item$acf21.immagine) === null || _item$acf21$immagine === void 0 ? void 0 : (_item$acf21$immagine$ = _item$acf21$immagine.sizes) === null || _item$acf21$immagine$ === void 0 ? void 0 : _item$acf21$immagine$.large : '',
        "date": (_item$acf22 = item.acf) === null || _item$acf22 === void 0 ? void 0 : _item$acf22.quando
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--specials', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--specials', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _vm.universities.length ? _c('section', {
    staticClass: "section university",
    attrs: {
      "id": "contenitore-partner"
    }
  }, [_c('header', [_c('h2', [_vm._v(_vm._s(_vm.$t('home.partnerUniversities')))]), _vm._v(" "), _vm.universities.length > 5 ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath({
        name: 'all-wp',
        params: {
          wp: 'universities'
        }
      })
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('home.seeAll')) + "\n          "), _c('fa', {
    attrs: {
      "icon": ['fas', 'chevron-right']
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "scroller"
  }, [_c('ul', {
    staticClass: "scroller__list scroller__list--universities"
  }, _vm._l(_vm.universities, function (item, index) {
    var _item$acf23, _item$acf24, _item$acf24$logo, _item$acf24$logo$size;
    return _c('University', {
      key: index,
      attrs: {
        "id": 'singolo-partner-' + (index + 1),
        "name": item.title.rendered,
        "link": (_item$acf23 = item.acf) === null || _item$acf23 === void 0 ? void 0 : _item$acf23.link,
        "image": item.acf.logo ? (_item$acf24 = item.acf) === null || _item$acf24 === void 0 ? void 0 : (_item$acf24$logo = _item$acf24.logo) === null || _item$acf24$logo === void 0 ? void 0 : (_item$acf24$logo$size = _item$acf24$logo.sizes) === null || _item$acf24$logo$size === void 0 ? void 0 : _item$acf24$logo$size.medium : ''
      }
    });
  }), 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__prev",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--universities', -1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-left']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Prev")])], 1), _vm._v(" "), _c('button', {
    staticClass: "scroller__next",
    on: {
      "click": function ($event) {
        return _vm.manualScroll('.scroller__list--universities', 1);
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fa', 'chevron-right']
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "visually-hidden"
  }, [_vm._v("Next")])], 1)])]) : _vm._e(), _vm._v(" "), _c('Popup', {
    attrs: {
      "type": 'orp-new',
      "visible": _vm.testPopup
    },
    on: {
      "hide-popup": function ($event) {
        return _vm.hidePopup();
      }
    }
  })], 1) : _c('main', [_c('h1', {
    staticClass: "splash"
  }, [_c('picture', [_c('source', {
    attrs: {
      "srcset": '/assets/images/il-nuovo-cving-' + _vm.$i18n.locale + '-1021w.webp',
      "media": "(min-width: 500px)",
      "type": "image/webp"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": '/assets/images/il-nuovo-cving-' + _vm.$i18n.locale + '-500w.webp',
      "type": "image/webp"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": '/assets/images/il-nuovo-cving-' + _vm.$i18n.locale + '-1021w.png',
      "media": "(min-width: 500px)",
      "type": "image/png"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": '/assets/images/il-nuovo-cving-' + _vm.$i18n.locale + '-500w.png',
      "type": "image/png"
    }
  }), _vm._v(" "), _c('img', {
    attrs: {
      "src": '/assets/images/il-nuovo-cving-' + _vm.$i18n.locale + '-500w.png',
      "alt": _vm.$t('home.splash').toString(),
      "width": "500",
      "height": _vm.$i18n.locale === 'de' ? 1348 : _vm.$i18n.locale === 'en' ? 1596 : _vm.$i18n.locale === 'es' ? 1596 : _vm.$i18n.locale === 'fr' ? 1348 : _vm.$i18n.locale === 'it' ? 1522 : _vm.$i18n.locale === 'de' ? 1596 : ''
    }
  })])]), _vm._v(" "), false ? _c('p', {
    staticClass: "hashtags"
  }, [_vm._v("\n      #cving2022 #crowdrecruitingplatform #comingsoon\n    ")]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }