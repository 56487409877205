
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { getVerticals } from '../store/languages/verticali'
import Carousel from './vuecarousel.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    Carousel
  },
  layout: 'longFooter',
  data () {
    return {
      mainAlwaysVisible: true,
      isMobile: false,
      getVerticali: [],
      testPopup: false,
      slider: [],
      dtw: [],
      companies: [],
      universities: [],
      news: [],
      events: [],
      specials: [],
      jobs: [],
      suggestable: [],
      stages: [],
      pills: [],
      coids: [],
      swiped: false,
      sito: this.$config.sito,
      $lazyLoad: null,
      $gtm: null,
      autoslide: null,
      search: {
        text: '',
        city: ''
      },
      $sanitizeTitle: null,
      isOrpVisible: false,
      placeholder: ''
    }
  },
  async fetch () {
    await this.fetchData()
    this.shuffleArray(this.companies)
    this.coids = this.companies.filter(company => parseInt(company.acf.coid) !== 0).map(company => parseInt(company.acf.coid))
    if (this.coids !== null && this.coids.length) {
      const params = {
        coids: this.coids
      }
      await this.$axios.post('/proxyapi/featured-companies-count', params).then((res: { data: { campaigns: string | any[] } }) => {
        if (res.data.campaigns !== null) {
          for (let i = 0; i < this.companies.length; i++) {
            for (let j = 0; j < res.data.campaigns.length; j++) {
              if (parseInt(this.companies[i].acf.coid) === res.data.campaigns[j].coid) {
                this.companies[i].count = res.data.campaigns[j].count
              }
            }
          }
        }
      }).catch(({ response }) => {
        console.log('Errore richiesta conteggio aziende:', response)
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(response)
      })
    }

    this.shuffleArray(this.universities)
    this.shuffleArray(this.specials) // Se ci servirà fare cose con ordine casuale

    // Campagne
    const paramsCampaign = {
      coids: this.coids,
      r: 2,
      ct: [0, 1, 2, 3, 4, 5, 6, 7]
    }
    await this.$axios.post('/proxyapi/featured-campaigns', paramsCampaign).then((res: { data: { campaigns: any[] } }) => {
      // console.log('success')
      if (res.data.campaigns !== null) {
        res.data.campaigns.forEach((c: { description: string }) => {
          c.description = c.description?.normalize('NFC')
        })
        this.jobs = res.data.campaigns != null ? res.data.campaigns : []
      }
    }).catch(({ response }) => {
      console.log('Errore richiesta jobs: ', response)
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(response)
    })

    await this.$axios.post('/proxyapi/orp-campaigns', { limit: true }).then((res: { data: { campaigns: any[] } }) => {
      if (res.data.campaigns !== null) {
        res.data.campaigns.forEach((c: { description: string }) => {
          c.description = c.description?.normalize('NFC')
        })
        this.suggestable = res.data.campaigns != null ? res.data.campaigns : []
      }
    }).catch(({ response }) => {
      console.log('Errore richiesta suggestables: ', response)
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(response)
    })

    // Stage
    const paramsStage = {
      coids: this.coids,
      r: 2,
      ct: [8]
    }
    await this.$axios.post('/proxyapi/featured-campaigns', paramsStage).then((res: { data: { campaigns: any[] } }) => {
      if (res.data.campaigns !== null) {
        res.data.campaigns.forEach((c: { description: string }) => {
          c.description = c.description?.normalize('NFC')
        })
        this.stages = res.data.campaigns != null ? res.data.campaigns : []
      }
    }).catch(({ response }) => {
      console.log('Errore richiesta stage:', response)
      if (this.$auth.loggedIn) {
        this.$sentry.setTag('email', this.$auth.user.email)
      }
      this.$sentry.captureException(response)
    })
  },
  head (): any {
    const json = {
      '@context': 'http://schema.org',
      '@type': 'Corporation',
      '@id': 'https://id.cving.com',
      legalName: 'CVing S.r.l.',
      url: 'https://cving.com',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Milano',
        addressCountry: 'Italia',
        postalCode: '20144',
        streetAddress: 'Via Tortona, 33'
      },
      logo: 'https://cving.com/logo-cving.png',
      sameAs: [
        'https://www.facebook.com/CVINGapp',
        'https://www.linkedin.com/company/cving/',
        'https://www.instagram.com/cving_app/',
        'https://www.crunchbase.com/organization/cving'
      ]
    }
    return {
      script: [{ type: 'application/ld+json', json }],
      bodyAttrs: {
        class: 'homepage'
      },
      title: 'CVing',
      meta: [
        { hid: 'description', name: 'description', content: (this as any).$t('generic.metaDescription') },
        { hid: 'og:description', name: 'og:description', content: (this as any).$t('generic.metaDescription') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      alreadySeePopupPersonality: 'popup/getPopupPersonality'
    }),
    getSlider() { return this.slider },
    link () {
      let link = ''
      if (this.search.city !== '') {
        if (this.search.text !== '') {
          link = this.localePath({ name: 'radar-city', params: { city: this.$sanitizeTitle(this.search.city) }, query: { search: this.$sanitizeTitle(this.search.text), radius: '500000', p: '1', ea: '1', verified: 'true', suggested: false, order: '0' } })
        } else {
          link = this.localePath({ name: 'radar-city', params: { city: this.$sanitizeTitle(this.search.city) }, query: { radius: '500000', p: '1', ea: '1', verified: 'true', suggested: false, order: '0' } })
        }
      } else if (this.search.text !== '') {
        link = this.localePath({ name: 'radar', query: { search: this.$sanitizeTitle(this.search.text), p: '1', ea: '1', verified: 'true', suggested: false, order: '0' } })
      }
      return link
    }
  },
  created () {
    const v = JSON.parse(JSON.stringify(getVerticals(this.$i18n.locale)))
    this.getVerticali = v
  },
  mounted () {
    window.addEventListener('resize', this.checkPlaceholder) // Aggiungi un listener per il ridimensionamento della finestra
    this.$nextTick(() => {
      this.autoslide = setInterval(() => {
        this.manualScroll('.home__list', 1, true, true)
      }, 5000)
      this.checkPlaceholder()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkPlaceholder)
  },
  methods: {
    ...mapActions({
      setPopupPersonality: 'popup/setPopupPersonality'
    }),
    async fetchData() {
      try {
        const sliderRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/lanci?per_page=10&_fields=acf.data,acf.descrizione,acf.link,acf.ordine,acf.slug,acf.sottotitolo,acf.tipo,acf.immagine.sizes.1536x1536,acf.immagine.sizes.large,acf.immagine.sizes.medium_large,title,acf.colore_testo,acf.sito,acf,immagine_mobile')
        sliderRes.data.forEach((c) => {
          c.acf.descrizione = c.acf?.descrizione?.normalize('NFC')
          c.acf.sottotitolo = c.acf?.sottotitolo?.normalize('NFC')
        })
        this.slider = sliderRes.data === null ? [] : sliderRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD').sort((a, b) => a.acf.ordine - b.acf.ordine)
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta slider: ', err)
      }

      try {
        const companiesRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/azienda-in-evidenza?per_page=10&_fields=acf.ordine,acf.link,acf.logo.sizes.medium,acf.immagine.sizes.large,acf.citta,acf.descrizione,title,acf.coid,acf.sito')
        companiesRes.data.forEach((c) => {
          c.acf.descrizione = c.acf?.descrizione?.normalize('NFC')
        })
        this.companies = companiesRes.data === null ? [] : companiesRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD')
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta companies: ', err)
      }

      try {
        const universitiesRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/universita?per_page=10&_fields=acf.ordine,acf.link,acf.logo.sizes.medium,title,acf.sito')
        this.universities = universitiesRes.data === null ? [] : universitiesRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD')
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta universities:', err)
      }

      try {
        const pillsRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/videopillole?per_page=10&_fields=acf.ordine,acf.link,acf.immagine.sizes.large,title,acf.sito,acf.colore_testo,date')
        this.pills = pillsRes.data === null ? [] : pillsRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD').sort((a, b) => Number(b.acf.ordine) - Number(a.acf.ordine) || b.date.localeCompare(a.date))
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta pills: ', err)
      }

      try {
        const newsRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/posts?per_page=10&categories_exclude=70&_embed&_fields=acf.descrizione,title,link,_links,date,yoast_head_json.description')
        newsRes.data.forEach((c) => {
          c.acf.descrizione = c.acf?.descrizione?.normalize('NFC')
          c.yoast_head_json.description = c.yoast_head_json?.description?.normalize('NFC')
          if (c._embedded['wp:featuredmedia'] !== undefined) {
            c._embedded['wp:featuredmedia']['0'].title = ''
            c._embedded['wp:featuredmedia']['0'].yoast_head = ''
            c._embedded['wp:featuredmedia']['0'].yoast_head_json = ''
          }
        })
        this.news = newsRes.data === null ? [] : newsRes.data
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta news: ', err)
      }

      try {
        const eventsRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/eventi?per_page=10&_fields=acf.descrizione,acf.dtw,acf.ordine,acf.link,acf.immagine.sizes.large,acf.quando,title,acf.sito')
        eventsRes.data.forEach((c) => {
          c.acf.descrizione = c.acf?.descrizione?.normalize('NFC')
        })
        this.events = eventsRes.data === null ? [] : eventsRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD').sort((a, b) => b.acf.quando.localeCompare(a.acf.quando))
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta events: ', err)
      }

      try {
        const specialsRes = await this.$axios.get('/proxymedia/wp-json/wp/v2/edizioni-speciali?per_page=10&_fields=acf.descrizione,acf.dtw,acf.ordine,acf.link,acf.immagine.sizes.large,acf.quando,title,acf.sito')
        specialsRes.data.forEach((c) => {
          c.acf.descrizione = c.acf?.descrizione?.normalize('NFC')
        })
        this.specials = specialsRes.data === null ? [] : specialsRes.data.filter(dtw => dtw.acf?.sito === this.$config.sito || dtw.acf?.sito === 'PROD')
      } catch (err) {
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(err)
        console.error('Errore nella richiesta specials: ', err)
      }
    },
    checkPlaceholder() {
      let str = this.$t('home.jobCompany').toString()
      const length = str.length
      const input = this.$refs.homewhat as HTMLElement
      if (input) {
        const inputWidth = input.clientWidth
        while ((str.length * 6.5) > inputWidth) {
          str = str.slice(0, -1)
        }
        if (length !== str.length) {
          str = str.slice(0, -3)
          str = str + '...'
        }
        this.placeholder = str
      } else {
        console.log('this.$refs.homewhat: ref not find')
      }
    },
    hidePopup () {
      this.testPopup = false
    },
    showPopup ({ target: { scrollTop } }) {
      if (this.$i18n.locale === 'it' && scrollTop > 1100 && !this.alreadySeePopupPersonality) {
        this.setPopupPersonality(true)
        this.testPopup = true
      }
    },
    manualScroll (classe: string, verse: number, full = false, auto = false) {
      const content = document.querySelector(classe)
      const singleScroll = document.getElementById('singola-slide-1')
      if (content !== null && content !== undefined) {
        if (!auto) {
          clearInterval(this.autoslide)
        }
        if (full) {
          if (content.scrollLeft === 0 && verse === -1) {
            content.scrollLeft = content.scrollWidth
          } else {
            content.scrollLeft += singleScroll.offsetWidth * verse
            if (content.scrollLeft > content.scrollWidth - singleScroll.offsetWidth * 2) {
              content.scrollLeft = 0
            }
          }
        } else {
          content.scrollLeft += 300 * verse
        }
      }
    },
    lazyLoadImage (e: { target: { parentNode: { querySelectorAll: (arg0: string) => any } } }) {
      const media = e.target.parentNode.querySelectorAll('[data-manual-lazy]');
      [...media].forEach((m) => { m.setAttribute('src', m.dataset.src) })
    },
    searchCampaigns () {
      this.$router.push(this.link)
    },
    shuffleArray (array: any[]) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
    }
  }
})
