
import Vue from 'vue'
export default Vue.extend({
  name: 'Carousel',
  props: {
    slides: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      isMobile: true,
      settings: {
        autoplay: true,
        infinite: true,
        arrows: true,
        centerMode: this.isMobile ? false : !(this.slides.length % 2),
        centerPadding: this.isMobile ? '0px' : this.slides.length % 2 ? '0px' : '25px',
        autoplaySpeed: 3500,
        pauseOnHover: true,
        speed: 1250
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize, false)
    this.onResize()
  },
  methods: {
    onResize () {
      if (window && (window.innerWidth < 688 || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i))) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPrev () {
      this.$refs.carousel.prev()
    }
  }
})
