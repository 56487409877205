
import Vue from 'vue'

export default Vue.extend({
  name: 'VideoSector',
  props: {
    videoId: {
      type: Number,
      default: () => 0
    },
    video: {
      type: Object,
      default: () => {}
    },
    onAir: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    sectorTitle () {
      return this.video[this.$i18n.locale] ? this.video[this.$i18n.locale] : ''
    },
    videoLink() {
      return this.video?.video
    },
    id() {
      return this.video?.id
    }
  },
  methods: {
    playVideo () {
      if (this.$refs[this.videoLink]) {
        this.$refs[this.videoLink].play()
      }
    },
    pauseVideo () {
      if (this.$refs[this.videoLink]) {
        this.$refs[this.videoLink].pause()
      }
    }
  }
})
