
import Vue from 'vue'

export default Vue.extend({
  name: 'Company',
  prop: ['value'],
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    positions: {
      type: Number,
      default: -1
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    track () {
      this.$gtm.push({
        event: 'click_company',
        event_data: {
          company_name: this.name,
          company_place: this.city,
          open_job_application: this.positions > 0,
          page_url: this.link,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
