import { render, staticRenderFns } from "./index.vue?vue&type=template&id=34ee1c26&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=34ee1c26&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34ee1c26",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Company: require('/usr/src/app/components/blocks/Company.vue').default,VideoSector: require('/usr/src/app/components/blocks/VideoSector.vue').default,Job: require('/usr/src/app/components/blocks/Job.vue').default,News: require('/usr/src/app/components/blocks/News.vue').default,VideoPill: require('/usr/src/app/components/blocks/VideoPill.vue').default,Event: require('/usr/src/app/components/blocks/Event.vue').default,Special: require('/usr/src/app/components/blocks/Special.vue').default,University: require('/usr/src/app/components/blocks/University.vue').default,Popup: require('/usr/src/app/components/Popup.vue').default})
